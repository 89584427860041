import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import animelibgx from "../../visual/projects/comingsoon.png";
import passgenxer from "../../visual/projects/passgenxer.png";
import econolabgx from "../../visual/projects/comingsoon.png";
import ledgerxer from "../../visual/projects/comingsoon.png";
import portfoliomgl from "../../visual/projects/portfoliomgl.png";
import { FormattedMessage } from "react-intl";
import Footer from "../Footer";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={portfoliomgl}
              isBlog={false}
              title="PortfolioMGL"
              description={
                <FormattedMessage
                  id="projects.portfolioMGL"
                  defaultMessage="Personal portfolio of the owner of GabyXer, Marcos G. Lorenzana. To maintain certain security, it will be necessary to register to see it."
                />}
              link="https://portfoliomgl.gabyxer.com.ar"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={passgenxer}
              isBlog={false}
              title="PassGenXer"
              description={
                <FormattedMessage
                  id="projects.passGenXer"
                  defaultMessage="Automatic, free and secure password generator that will allow you to protect yourself from cyber attacks. Nowadays it is essential to have this type of tools and, for this reason, we offer you our own version."
                />}
              link="https://passgenxer.gabyxer.com.ar"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ledgerxer}
              isBlog={false}
              title="LedGerXer"
              description={
                <FormattedMessage
                  id="projects.ledGerXer"
                  defaultMessage="Set of accounting tools used by Excel integrated with React to allow the user to easily and intuitively make their records and save them."
                />}
              link=""              
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={animelibgx}
              isBlog={false}
              title="AnimeLibGX"
              description={
              <FormattedMessage
                id="projects.animeLibGX"
                defaultMessage="Anime library oriented to Latin America, where you will find your favorite animes in detail. Also, you will be able to register a user to know which were your last visited animes and which ones you have rated!"
              />}
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={econolabgx}
              isBlog={false}
              title="EconoLabGX"
              description={
                <FormattedMessage
                  id="projects.econoLabGX"
                  defaultMessage="Experimental site where the greatest amount of information on Economic Sciences will be captured to share it for free and allow the user to learn and review through various study tools."
                />}
              link=""              
            />
          </Col> */}
          
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Projects;
