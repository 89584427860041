import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {!props.isBlog  && (
          <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
            <div>
              <Link
                to={props.link && props.link !== "inprogress" ? props.link : "/inprogress"}
                { ...(props.link === "" ? {} : { target: "_blank" }) }
              >
                <Button variant="primary">
                  <CgWebsite /> &nbsp;
                    <FormattedMessage
                      id="projects.buttonCard"
                      defaultMessage="Go!"
                    />
                </Button>
              </Link>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
