import { Link } from "react-router-dom";
import "./EnProceso.css";
import { FormattedMessage } from "react-intl";
import Footer from "../components/Footer";


export function EnProceso() {
    return (
        <>
            <div className="contenedorEnProceso">
                <h1 className="enProceso">
                    <FormattedMessage
                        id="inProgress.title"
                        defaultMessage="PROJECT IN PROCESS"
                    />  
                </h1>
                <Link className='linkRegresarInicio' to="*">
                      <span>← {" "}
                        <FormattedMessage
                            id="inProgress.text"
                            defaultMessage="Return"
                        />
                        </span>
                </Link>
            </div>
            <Footer />
        </>
    )
}