import React, { useState, useEffect } from "react";
import { ImCircleUp } from "react-icons/im";
import "./HaciaArriba.css";

const BotonArriba = () => {
  const [showButton, setShowButton] = useState(false);

  const haciaArriba = () => {
    const currentScroll = document.documentElement.scrollTop;
    if (currentScroll > 0) {
      window.scrollTo(0, 0);
    } else {
      setShowButton(false);
    }
  };

  const handleScroll = () => {
    const scroll = document.documentElement.scrollTop;
    setShowButton(scroll > 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <div className="botonArriba" onClick={haciaArriba}>
          <ImCircleUp />
        </div>
      )}
    </>
  );
};

export default BotonArriba;