import React from "react";
import "../style.css"
import { FaPatreon, FaPaypal } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const Donations = () => {
    return(
        <div className="contenedorDonations">
            <h3 style={{ fontSize: "1.5em", color: "#000", margin: 0 }}>
              <FormattedMessage
                id="home.donationsTitle"
                defaultMessage="Thanks for the support!"
              />
            </h3>
            <ul>
              <li>
                <a href="https://www.paypal.com/donate/?hosted_button_id=39LRKV3GZBC22&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWxvYmplY3RzLmNvbS9kb25hdGUvc2RrL2RvbmF0ZS1zZGsuanMiLCJhdHRycyI6eyJkYXRhLXVpZCI6IjU4MWZjN2Y5ZmZfbXRxNm10bTZuZGkifX0&targetMeta=eyJ6b2lkVmVyc2lvbiI6IjlfMF81OCIsInRhcmdldCI6IkRPTkFURSIsInNka1ZlcnNpb24iOiIwLjguMCJ9" target="_blank" rel="noreferrer">
                  <FaPaypal className="iconDonate" />
                  <h6 className="PayPalTitle">PayPal</h6>
                </a>
              </li>
              <li>
                <a href="https://www.patreon.com/GabyXer" target="_blank" rel="noreferrer">
                  <FaPatreon className="iconDonate" />
                  <h6 className="PatreonTitle">Patreon</h6>
                </a>
              </li>
            </ul>
        </div>
    )
}

export default Donations;