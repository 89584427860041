import React, { useContext, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineFundProjectionScreen } from "react-icons/ai";
import es from '../visual/argentina.png';
import en from '../visual/united-states.png';
import {FormattedMessage} from 'react-intl';
import {langContext} from '../contexto/langContext';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const idioma = useContext(langContext);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <div className="containerNavbarTitle">
            <svg viewBox="0 0 960 300">
              <symbol id="s-text">
                <text textAnchor="middle" x="50%" y="80%" className="navbarTitle">GabyXer</text>
              </symbol>

              <g className = "gAntsNavbar">
                <use xlinkHref="#s-text" className="navbarTextCopy"></use>
                <use xlinkHref="#s-text" className="navbarTextCopy"></use>
                <use xlinkHref="#s-text" className="navbarTextCopy"></use>
                <use xlinkHref="#s-text" className="navbarTextCopy"></use>
                <use xlinkHref="#s-text" className="navbarTextCopy"></use>
              </g>
            </svg>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> 
                <text className="navbarFormattedMessage">
                  <FormattedMessage 
                    id="navbar.home"
                    defaultMessage="Home"
                  />
                </text>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                <text className="navbarFormattedMessage">
                  <FormattedMessage 
                    id="navbar.projects"
                    defaultMessage="Projects"
                  />
                </text>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <div className="banderas">
					      <button onClick={() => idioma.establecerLenguaje('es-AR')}><img src={es} alt="Español"/></button>
					      <button onClick={() => idioma.establecerLenguaje('en-US')}><img src={en} alt="Inglés"/></button>
				      </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
