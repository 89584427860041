import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>
            <FormattedMessage id="footer.copyright" defaultMessage="Copyright"/> &copy; {year} GabyXer. 
          </h3>
          <h3>
          <FormattedMessage id="footer.text" defaultMessage="All rights reserved."/>
          </h3>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
