import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import { FormattedMessage } from "react-intl";
import { langContext } from "../../contexto/langContext";
import Footer from "../Footer";

function Home() {
  const { establecerLenguaje } = useContext(langContext);
  const idioma = localStorage.getItem('lang');

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                <FormattedMessage 
                  id="home.welcome"
                  defaultMessage="Our reality"
                />
              </h1>
              <p className="heading-name">
                <FormattedMessage 
                  id="home.presentation"
                  defaultMessage="At GabyXer we constantly seek to improve ourselves and offer useful tools to the public."
                  values={
                    {
                      inglés1: <b className="green">constantly seek to improve ourselves</b>,
                      inglés2: <b className="green">useful tools</b>,
                      español1: <b className="green">perfeccionarnos constantemente</b>,
                      español2: <b className="green">herramientas útiles</b>
                    }
                  }
                />
              </p>
              <Home2 />
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </section>
  );
}

export default Home;
