import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillInstagram, AiOutlineFacebook, AiOutlineMail, AiOutlineTwitter, AiOutlineYoutube } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import {FormattedMessage} from 'react-intl';
import { Link } from "react-router-dom";
import Donations from "../../routes/Donations";

function Home2() {
  const correoElectronico = "marcos.lorenzana@gabyxer.com.ar";

  const [showVentana, setShowVentana] = useState(false);

  const handleEtiquetaClick = () => {
    setShowVentana(true);
  };

  const handleCloseVentana = () => {
    setShowVentana(false);
  };

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.4em", paddingLeft: ".2em" }}>
              <FormattedMessage
                id="home.presentationAbout"
                defaultMessage="About the projects"
                values={
                  {
                    inglés: <b className="green">projects</b>,
                    español: <b className="green">proyectos</b>
                  }
                }
              />
            </h1>
            <p className="home-about-body">
              <p>
                <FormattedMessage
                  id="home.presentationText1"
                  defaultMessage="Always open to encompass even more ideas, the projects are varied and different from each other."
                  values={
                    {
                      inglés1: <b className="green">varied</b>,
                      inglés2: <b className="green">different</b>,
                      español1: <b className="green">variados</b>,
                      español2: <b className="green">diferentes</b>
                    }
                  }
                />
              </p>
              <br />
              <p>
                <FormattedMessage
                  id="home.presentationText2"
                  defaultMessage="Each one was created by a single individual, GabyXer's owner, Marcos Gabriel Lorenzana. Therefore, we accept external opinions."
                  values={
                    {
                      inglés1: <b className="green">Marcos Gabriel Lorenzana</b>,
                      inglés2: <b className="green">external opinions</b>,
                      español1: <b className="green">Marcos Gabriel Lorenzana</b>,
                      español2: <b className="green">opiniones externas</b>
                    }
                  }
                />
              </p> 
              <br />
              <p>
                <FormattedMessage
                  id="home.presentationText3"
                  defaultMessage="You can see each one in detail by clicking on the 'Projects' section."
                  values={
                    {
                      inglés: <Link to="/project" className="linkGreen"><b>'Projects'</b></Link>,
                      español: <Link to="/project" className="linkGreen"><b>'Proyectos'</b></Link>
                    }
                  }
                />
              </p>
              <br />
              <p>
                <FormattedMessage
                  id="home.presentationText4"
                  defaultMessage="Also, donations are enabled and we appreciate any kind of help."
                  values={
                    {
                      inglés1: <a className="linkGreen" onClick={handleEtiquetaClick}><b>donations</b></a>,
                      inglés2: <b className="green">help</b>,
                      español1: <a className="linkGreen" onClick={handleEtiquetaClick}><b>donaciones</b></a>,
                      español2: <b className="green">ayuda</b>
                    }
                  }
                />
              </p>
            </p>
          </Col>
          {showVentana && (
            <div className="contenedorVentanaEmergente">
              <div className="ventanaEmergente">
                <button className="closeButton" onClick={handleCloseVentana}>
                  X
                </button>
                <Donations/>
              </div>
            </div>
          )}
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              <FormattedMessage
                  id="home.presentationPublicityText"
                  defaultMessage="Social networks and contact media"
                />
            </h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href={`mailto:${correoElectronico}`}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.youtube.com/channel/UCLyjgjVChf6DnEJqOpoOfzQ"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineYoutube />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/GabyXer"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTelegram/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gabitox218/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100090249294349"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Gabitox218"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter/>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
